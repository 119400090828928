const { default: Swal } = require("sweetalert2");

const templateId = "achievement-cell";
const sampleBadge = {
  title: "일수 곽두팔",
  level: "전설",
  image_url: "https://playtheworld-opengame.s3.ap-northeast-2.amazonaws.com/badges_2/01_badge_+medal_of_honor.svg",
  theme_title: "냥토리얼",
  content: "한때 양재에서 큰손이라 불리던 과두팔이라고 종종 두팔이는 상상 한다.",
  content2: "_ 냥토리얼 테마 플레이 완료"
}

export function initAchievementModal() {
  document.querySelectorAll('.achievement-cell-wrapper').forEach(el => {
    el.addEventListener('click', e => {
      const data = {
        title: el.dataset.title,
        level: el.dataset.level,
        image_url: el.dataset.imageUrl,
        theme_title: el.dataset.themeTitle,
        content: el.dataset.content,
        content2: el.dataset.content2,
        disabled: el.dataset.disabled === 'true' ? 'disabled' : ''
      };
      
      Swal.fire({
        showConfirmButton: false,
        html: render({
          template: templateId,
          data: data
        })
      });
    });
  });
}

// onload(() => {
//   Swal.fire({
//     showConfirmButton: false,
//     html: render({
//       template: templateId,
//       data: sampleBadge
//     })
//   });
// });