import Swiper from "swiper";
import API from "../lib/api";
import SuperTheme from "../model/super_theme";

export const SWIPER_CLASS = "theme-swiper"

export function initThemeSwiper() {
  document.querySelectorAll(`.${SWIPER_CLASS}`).forEach((e) => {
    // if (!e.swiper && e.offsetWidth !== 0) {
    let breakpoints = {
      375: {
        slidesPerView: 2.2,
        // spaceBetween: 8,
      },
      500: {
        slidesPerView: 3.5,
        // spaceBetween: 4,
      }
    }
    let slidesPerView = 2.2
    if (e.dataset.slidesPerView) {
      // console.log(parseFloat(e.dataset.slidesPerView))
      slidesPerView = parseFloat(e.dataset.slidesPerView)
    }
    if (e.dataset.noBreakpoints) {
      breakpoints = {}
    }
    if (!e.swiper) {
      new Swiper(e, {
        slidesPerView,
        spaceBetween: 8,
        breakpoints,
        // on: {
        //   click: (swiper) => {
        //     console.log('activeIndex: ', swiper.activeIndex)
        //     console.log('activeSlide: ', swiper.activeSlide)
        //     console.log('clickedIndex: ', swiper.clickedIndex)
        //     console.log('clickedSlide: ', swiper.clickedSlide)
        //     console.log('realIndex: ', swiper.realIndex)
        //   }
        // }
      });
    } else {
      e.swiper?.update();
    }
  });
}

onload(() => {
  initThemeSwiper();

   // 추천 테마 swiper
   for (const el of document.querySelectorAll(`.theme-swiper[data-type='recommend']`)) {
    const id = el.dataset.themeId;

    if (!id || !el.swiper || el.refreshed || el.swiper.slides.length !== 0) continue;
    el.refreshed = true;
    
    API.themes.recommend
      .withId(id)
      .call()
      .then(res => {
        let data = res.data;
        if (appData().native == "iOS")
          data = data.filter(e => e.title !== "코로나19 예방수칙");

        data = data.map((v) => new SuperTheme(v))

        el.swiper.appendSlide(render({
          template: 'theme-slide',
          data: data
        }));
        el.closest('.theme-swiper-wrapper').classList.remove('loading');
        el.swiper.update();
      });
  }

  // Topic
  for (const el of document.querySelectorAll(`.theme-swiper[data-type='topic']`)) {
    const topicTitle = el.dataset.topicTitle;

    if (!topicTitle || !el.swiper || el.refreshed || el.swiper.slides.length !== 0) continue;
    el.refreshed = true;
    
    API.themes.topics
      .call({
        title: topicTitle
      })
      .then(res => {
        let data = res.data;
        if (appData().native == "iOS")
          data = data.filter(e => e.title !== "코로나19 예방수칙");

        data = data.map((v) => new SuperTheme(v))

        el.swiper.appendSlide(render({
          template: 'theme-slide',
          data: data
        }));
        el.closest('.theme-swiper-wrapper').classList.remove('loading');
        el.swiper.update();
      });
  }
}, {});

// 터보링크 이동 시마다 호출되는거 어케 막냐..
// window.addEventListener('DOMContentLoaded', e => {
 
// });