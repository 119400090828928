import Swiper, { Pagination, Autoplay } from 'swiper';
import API from '../lib/api';

Swiper.use([Pagination, Autoplay]);

const TEMPLATE_ID = "banner-super-theme";
export const SWIPER_CLASS = "banner-swiper";

export function refreshBanners(params = {}) {
  API.themes.banners
    .call(params)
    .then(res => {
      let data = res.data;

      if (appData().native == "iOS")
        data = data.filter(e => e.super_theme?.title !== "코로나19 예방수칙");
      // alert(data[1].super_theme.title)
      // console.log(data)
      
      const target = document.querySelector(`.${SWIPER_CLASS} .swiper-wrapper`)
      target.innerHTML = ''

      for(const d of data) {
        let tempId = TEMPLATE_ID

        switch(d.type) {
          case 'image':
            tempId = "banner-image"
            break
        }

        render({
          append: true,
          template: tempId,
          target: target,
          data: [d]
        });
      }
      
      initBannerSwiper();
    });
}

export function initBannerSwiper() {
  document.querySelectorAll(`.${SWIPER_CLASS}`).forEach((e) => {
    if (!e.swiper && e.offsetWidth !== 0) {
      new Swiper(e, {
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 1,
        spaceBetween: 8,
        loop: true,
        centeredSlides: true,
        pagination: {
          el: e.querySelector('.swiper-pagination'),
          // el: '.swiper-pagination',
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            // return current + ' of ' + total;
            return `<div><span>${current}</span> / ${total}</div>`;
          }
        },
      });
      e.classList.remove('loading');
      // e.swiper.pagination.init();
    }
    else {
      e.swiper.update();
    }
  });
}

// onload(() => {
//   refreshBanners();
// });