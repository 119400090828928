import API from '@/lib/api';
import { toggleModal, status, slideBack } from '../lib';
import { validatePassword } from '@/lib/utils/validate';
import Slide from './base';

export default class ResetPasswordSlide extends Slide {
  static slideName = "reset-password";

  static update = () => {
    if (validatePassword(this.input.value)) this.btn.classList.remove('disabled');
    else this.btn.classList.add('disabled');
  }

  static refresh = () => {
    this.placeholder.value = status.user.email || "";
  }

  static click = () => {
    if (status.isRequesting || !validatePassword(this.input.value)) return;
    status.isRequesting = true;
    
    status.user.password = this.input.value;
    API.sessions.resetPassword
      .call(status.user)
      .then(res => {
        status.user = res.data || {};

        switch(res.code) {
          case 1:
            Swal.fire({
              title: '비밀번호 재설정 완료',
              text: "비밀번호 재설정이 성공적으로 완료되었습니다. 해당 계정으로 로그인됩니다.",
              confirmButtonText: '확인',
            }).then((result) => {
              toggleModal(false);
              redirectTo(status.redirectUrl, true);
            });
            break;
          case 2:
            slideBack(2);
            break;
          case 3:
            Swal.fire({
              title: '비밀번호 재설정 완료',
              text: "비밀번호 재설정이 성공적으로 완료되었습니다.",
              confirmButtonText: '확인',
            }).then((result) => {
              toggleModal(false);
              redirectTo(status.redirectUrl, true);
            });
            break;
        }
      }).catch(error => {
        switch(error.code) {
          case 1:
            this.onError(error.message);
            break;
          default:
            Swal.fire({
              title: '오류',
              text: error.message || "알 수 없는 에러!",
              confirmButtonText: '확인',
            }).then((result) => {
              slideBack();
            });
            break;
        }
      }).finally(() => {
        status.isRequesting = false;
      });
  }
}