export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(string) {
  // 최소 8 자, 최소 하나의 문자 및 하나의 숫자 :
  // "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
  // 최소 8 자, 최소 하나의 문자, 하나의 숫자 및 하나의 특수 문자 :
  // "^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$"
  // 최소 8 자, 대문자 하나 이상, 소문자 하나 및 숫자 하나 :
  // "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
  // 최소 8 자, 대문자 하나 이상, 소문자 하나, 숫자 하나 및 특수 문자 하나 이상 :
  // "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}"
  // 최소 8 자 및 최대 10 자, 대문자 하나 이상, 소문자 하나, 숫자 하나 및 특수 문자 하나 이상 :
  // "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,10}"

  // 하나 이상의 문자(특문포함) 및 숫자의 조합. 총 6글자 이상
  const re = /^(?=.*[\D])(?=.*\d)[\D\d]{6,}$/;
  return re.test(string);
}

export function validateNickname(nickname) {
  const re = /^[가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9]{2,8}$/;
  return re.test(nickname);
}

export function validateConfirmation(string) {
  const re = /^\d{6}$/;
  return re.test(string);
}

export function validatePhoneNumber(string) {
  const re = /^\d{10,11}$/;
  return re.test(string);
}