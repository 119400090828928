import NetworkError from './error';
import { GET } from './const';

// Network는 통신만을 담당
export default class Network {
  static baseUrl = 'http://localhost:3000';

  static async request(url, method = GET, payload = {}, skip_json = false) {
    return new Promise((resolve, reject) => {
      const _url = skip_json ? url : `${url}.json`
      const config = {
        url: _url,
        type: method,
        data: payload,
        dataType: 'json',
        withCredentials: true,
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
        success: res => {
          if (appData().env === "development")
            console.log(res);
          // TODO: Response Class 만들기
          res.code ||= 999;
          res.message ||= "OK";
          res.data ||= {};
          resolve(res);
        },
        error: error => {
          if (appData().env === "development")
            console.log(error);
          // if (error.message) toastr.error(error.message);
          const res = error.responseJSON;
          const err = new NetworkError(res?.message, res?.code, res?.data, error.status);
          reject(err);
        }
      }

      if (payload.constructor === FormData) {
        config.processData = false;
        config.contentType = false;
      }
      
      $.ajax(config);
    });
  }
}