import { METHODS, ID } from './const';
import Network from './network';

// 특정 기능을 수행하는 API는 Method와 URL 둘 중 하나라도 틀리면 동작하지 않으므로 묶어서 EndPoint를 정의
export default class EndPoint {
	constructor(options) {
		// 예외처리
		if (options.url == null || options.method == null) throw Error("URL, 메소드 정의 필요.");
		if (!METHODS.includes(options.method)) throw Error("올바르지 않은 메소드");

		this._url = options.url;
		this.method = options.method;
	};

	get url() {
		// return `${Network.baseUrl}/${this._url}`;
		return `/${this._url}`;
	}

	get idCount() { return (this.url.match(/\$\{id\}/g) || []).length; }
	get currentIdCount() { return (this.id || []).length; }

	// ID가 필요한 경우 설정
	withId(id) {
		if (Array.isArray(id)) this.id = id;
		else if (Array.isArray(this.id)) this.id.push(id);
		else this.id = [id];

		return this;
	};

	// API 요청 보내기
	call(payload = {}) {
		let url = `${this.url}`;

		// ID설정이 필요한 Request라면?
		if (this.currentIdCount < this.idCount) throw Error("id 설정이 필요한 Request입니다.");

		for (let i = 0; i < this.idCount; i++)
			url = url.replace(ID, this.id[i]); // Request에 id설정
		this.id = undefined; // Request 이후 초기화
		
		return Network.request(url, this.method, payload);
	};
}
