import API from '../lib/api';
import toastr from 'toastr';
import { autoHeight } from '../lib/ui/textarea';
import { toggleModal } from '../sessions/lib';

export function fetchComments() {
  API.reviews.comments.index
    .withId(document.querySelector('.reviews-footer').dataset.reviewId)
    .call()
    .then(res => {
      document.querySelector(`.reviews-show-wrapper > .comments-wrapper`).innerHTML = render({
        template: 'comment-cell',
        data: res.data
      });

      initCells('.review-cell-wrapper.comment');
      document.querySelector(`.reviews-show-wrapper > .comments-wrapper`).classList.remove('loading');
    })
}

// TYPES = 'funny', 'like'

function toggleLike(element) {
  if (element.requesting) return;
  const reviewId = element.dataset.reviewId;
  const commentId = element.dataset.commentId;
  const type = element.dataset.type;
  if ((!reviewId && !commentId) || !type) return;
  element.requesting = true;

  const request = commentId ? API.reviews.comments.like : API.reviews.like;
  request
    .withId([reviewId || '0', commentId])
    .call({
      type: type
    })
    .then(res => {
      // toastr.success(res.message);

      // const cell = document.querySelector(`.review-cell-wrapper[data-review-id='${id}']`);
      const cell = element.closest(`.review-cell-wrapper`);
      if (!cell) return;
      cell.querySelectorAll('span.type-reply').forEach(e => { e.classList.remove('clicked'); });
      if (res.liked) cell.querySelector(`span.type-reply.${type}`)?.classList?.add('clicked');
      
      cell.querySelector('span.type-reply.like > strong.reply-count').innerText = res.likes_count || 0;
      cell.querySelector('span.type-reply.funny > strong.reply-count').innerText = res.funnies_count || 0;
    })
    .catch(err => {
      toastr.error(err.message);
    })
    .finally(() => {
      element.requesting = false;
    });
}

// init Cell
export function initCells(query = '.review-cell-wrapper') {
  const cells = document.querySelectorAll(query);

  for (const cell of cells) {
    for (const e of cell.querySelectorAll('.type-reply')) {
      e.onclick = evt => {
        toggleLike(e);
        evt.stopPropagation();
      }
    }

    const reportBtn = cell.querySelector('span.type-report');
    if (reportBtn) {
      reportBtn.onclick = evt => {
        evt.stopPropagation();
        report(cell);
      }
    }

    const editBtn = cell.querySelector('span.edit');
    if (editBtn) {
      editBtn.onclick = evt => {
        evt.stopPropagation();
        edit(cell);
      }
    }

    const removeBtn = cell.querySelector('span.remove');
    if (removeBtn) {
      removeBtn.onclick = evt => {
        evt.stopPropagation();
        remove(cell);
      }
    }
  }
}

function report(cell = undefined) {
  if (!cell) return;

  const e = cell.querySelector('span.type-report')
  const id = e.dataset.id;

  Swal.fire({
    title: '신고하시겠습니까?',
    text: "여러 번의 신고가 누적된 경우, 해당 게시글의 내용이 블라인드 처리됩니다.",
    showCancelButton: true,
    // showDenyButton: true,
    confirmButtonText: '신고',
    cancelButtonText: '취소',
    // denyButtonText: '취소'
  }).then((result) => {
    if (!result.isConfirmed && !e.isRequesting) return;
    e.isRequesting = true;

    switch(e.dataset.type) {
      case "Review":
        API.reviews.report
          .withId(id)
          .call()
          .then(res => {
            console.log(res)
            // cancel();
            // redirectTo(footer()?.dataset?.defaultUrl);
            toastr.success('신고 처리가 완료되었습니다.')
          })
          .catch(err => {
            toastr.error(err.message);
          })
          .finally(() => {
            e.isRequesting = false;
          });
        break;
      case "Comment":
        API.reviews.comments.report
          .withId([0, id])
          .call()
          .then(res => {
            // fetchComments();
            // cancel();
            toastr.success('신고 처리가 완료되었습니다.')
          })
          .catch(err => {
            toastr.error(err.message);
          })
          .finally(() => {
            e.isRequesting = false;
          });
        break;
      default:
        e.isRequesting = false;
    }
  });
}

function edit(cell = undefined) {
  // console.log(footer()?.dataset?.type, cell)
  if (!textArea() || !cell) return;

  switch(cell.dataset.type) {
    case "Review":
      footer().dataset.reviewId = cell.dataset.id;
      footer().dataset.type = 'edit';
      break;
    case "Comment":
      footer().dataset.commentId = cell.dataset.id;
      footer().dataset.type = 'comment-edit';
      break;
    default:
      return;
  }
  
  textArea().value = cell.querySelector('.review-cell-content')?.innerText || "";
  textArea().dispatchEvent(new Event('input'));
  textArea().focus();
  cancelBtn().classList.remove('hide');
  sendBtn().innerText = "수정";
}

function cancel(e = cancelBtn()) {
  sendBtn().innerText = "작성";
  cancelBtn().classList.add('hide');
  delete e.dataset.type;
  textArea().value = "";
}

function remove(cell = undefined) {
  if (!cell) return;

  const e = cell.querySelector('span.remove')
  const id = e.dataset.id;

  // TODO: 삭제버튼 빨간색으로 바꾸기
  Swal.fire({
    title: '정말로 삭제하시겠습니까?',
    text: "이 작업은 되돌릴 수 없습니다.",
    showCancelButton: true,
    // showDenyButton: true,
    confirmButtonText: '삭제',
    cancelButtonText: '취소',
    // denyButtonText: '취소'
  }).then((result) => {
    if (!result.isConfirmed && !e.isRequesting) return;
    e.isRequesting = true;

    switch(e.dataset.type) {
      case "Review":
        API.reviews.delete
          .withId(id)
          .call()
          .then(res => {
            cancel();
            redirectTo(footer()?.dataset?.defaultUrl);
          })
          .catch(err => {
            toastr.error(err.message);
          })
          .finally(() => {
            e.isRequesting = false;
          });
        break;
      case "Comment":
        API.reviews.comments.delete
          .withId([0, id])
          .call()
          .then(res => {
            fetchComments();
            cancel();
          })
          .catch(err => {
            toastr.error(err.message);
          })
          .finally(() => {
            e.isRequesting = false;
          });
        break;
      default:
        e.isRequesting = false;
    }
  });
}

function footer() { return document.querySelector('.reviews-footer'); }
function textArea() { return footer()?.querySelector('textarea'); }
function sendBtn() { return footer()?.querySelector('button.send'); }
function cancelBtn() { return footer()?.querySelector('button.cancel'); }

export function initFooter() {
  if (!footer() || !textArea() || !sendBtn() || !cancelBtn()) return;
  textArea().onclick = undefined;

  if (textArea().classList.contains('need-login')) {
    textArea().value = "로그인이 필요한 서비스입니다.";
    textArea().readOnly = "true";
    textArea().onclick = e => { toggleModal(true); }
  } else if (textArea().classList.contains('need-play')) {
    textArea().value = "테마 플레이 후 댓글을 작성할 수 있습니다.";
    textArea().readOnly = "true";
  } else {
    textArea().removeAttribute('readonly');
    textArea().value = "";
    sendBtn().onclick = evt => { send(footer()); }
    cancelBtn().onclick = evt => { cancel(footer()); }
  }

  autoHeight(textArea());
}

function send(e = sendBtn()) {
  const type = e?.dataset?.type || e?.dataset?.defaultType;
  if (!type || !e || e.isRequesting) return;
  if (textArea().classList.contains('need-login')) {
    toggleModal(true);
    return;
  } else if (textArea().classList.contains('need-play')) {
    return;
  }
  e.isRequesting = true;

  let request;
  const reviewId = footer().dataset.reviewId;
  const commentId = footer().dataset.commentId;
  
  switch (type) {
    case 'comment-edit':
      request = API.reviews.comments.update
        .withId([reviewId, commentId])
        .call({
          contents: textArea().value
        })
        .then(res => {
          fetchComments();
          cancel();
        })
        .catch(err => {
          toastr.error(err.message);
          switch(err.status) {
            case 401:
              toggleModal(true);
          }
        })
        .finally(() => {
          e.isRequesting = false;
        });
      break;
    case 'edit':
      request = API.reviews.update
        .withId(reviewId)
        .call({
          contents: textArea().value
        })
        .then(res => {
          const cell = document.querySelector(`.review-cell-wrapper[data-type='Review'][data-id='${res.data.id}']`);
          
          toastr.success("댓글이 수정되었습니다.");
          if (cell) cell.querySelector('.review-cell-content').innerText = res.data.contents;
          else redirectTo();
          cancel();
        })
        .catch(err => {
          toastr.error(err.message);
          switch(err.status) {
            case 401:
              toggleModal(true);
          }
        })
        .finally(() => {
          e.isRequesting = false;
        });
      break;
    case 'comment':
      request = API.reviews.comments.create
        .withId(reviewId)
        .call({
          contents: textArea().value
        })
        .then(res => {
          cancel();
          fetchComments();
        })
        .catch(err => {
          toastr.error(err.message);
          switch(err.status) {
            case 401:
              toggleModal(true);
          }
        })
        .finally(() => {
          e.isRequesting = false;
        });
      break;
    case 'review':
      request = API.reviews.create
        .call({
          // super_theme_id: e.dataset.superThemeId,
          theme_id: e.dataset.themeId,
          contents: textArea().value
        })
        .then(res => {
          cancel();
          // RELOAD?
          redirectTo(`/reviews/${res.data.id}`);
        })
        .catch(err => {
          toastr.error(err.message);
          switch(err.status) {
            case 401:
              toggleModal(true);
          }
        })
        .finally(() => {
          e.isRequesting = false;
        });
      break;
    default:
      e.isRequesting = false;
  }

  // request?.finally(() => {
  //   e.isRequesting = false;
  // });
}