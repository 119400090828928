import API from '@/lib/api';
import { status, slideBack } from '../lib';
import { validateEmail } from '@/lib/utils/validate';
import configs from '../../lib/configs';
import Slide from './base';

export default class ChangeEmailSlide extends Slide {
  static slideName = 'change-email';

  static update = () => {
    status.user.new_email = this.input.value;

    if (validateEmail(this.input.value)) this.btn.classList.remove('disabled');
    else this.btn.classList.add('disabled');
  }

  static refresh = () => {
    this.input.value = status.user.new_email || "";
  }

  static click = () => {
    if (configs.isTest) {
      slideBack();
      return;
    }
    
    if (status.isRequesting) return;
    status.isRequesting = true;

    API.sessions.changeEmail
      .call(status.user)
      .then(res => {
        status.user = res.data || {};

        switch(res.code) {
          case 1:
            Swal.fire({
              title: '알림',
              text: res.message,
              confirmButtonText: '확인',
            }).then((result) => {
              slideBack();
            });
            break;
        }
      }).catch(error => {
        switch(error.code) {
          case 1:
          case 3:
            this.onError(error.message);
            break;
          default:
            Swal.fire({
              title: '오류',
              text: error.message || "알 수 없는 에러!",
              confirmButtonText: '확인',
            }).then((result) => {
              slideBack(250, 2);
            });
            break;
        }
      }).finally(() => {
        status.isRequesting = false;
      });
  }
}