import Mustache from 'mustache';
import 'turbolinks';

// console.log('load!')

// https://stackoverflow.com/questions/56167445/something-is-scrolling-back-to-0-0-after-document-loaded
history.scrollRestoration = 'manual';

export function onload(func, options = { once: true }) {
  // console.log(options);
  if (Turbolinks) document.addEventListener("turbolinks:load", func, options);
  else $(document).ready(func);
}

export function redirectTo(url = undefined, forceLocation = false) {
  if (!url) {
    if (Turbolinks && !forceLocation) Turbolinks.visit(location.toString());
    else location.reload();
  } else {
    if (url === 'back') window.history.back();
    else if (Turbolinks && !forceLocation) Turbolinks.visit(url);
    else location.href = url;
  }
}

window.redirectTo = redirectTo;

// { tmplate, data, target }
export function render(options) {
  const template = document.getElementById(options.template).innerHTML;
  const compiled = Mustache.render(template, options.data);
  if (options.target) {
    if (options.append) options.target.innerHTML += compiled;
    else options.target.innerHTML = compiled;
  } else {
    return compiled;
  }
}

export function appData() {
  return document.getElementById('app')?.dataset || {};
}