const DEFAULT_ERROR_CODE = 999

export default class NetworkError {
  constructor(message, code, data, status) {
    this.message = message || "알 수 없는 에러.";
    this.code = code || DEFAULT_ERROR_CODE;
    this.data = data || {};
    this.status = status || 400;

    if (this.status === 401) {
      this.message = '로그인이 필요한 서비스입니다.';
      this.code = 401;
    }
  }
}