import { status } from '../../lib';
// import SnsSlide from './base';
import Slide from '../base';
import toastr from 'toastr';

export default class GoogleSlide extends Slide {
  static slideName = "sns";
  static type = 'google';
  
  static init = () => {
  }
  static refresh = () => {
    window.oAuthErrorHandler = this.handleError;
    this.auth();
  }
  static click = () => {
    this.auth();
  }
  // 인증
  static auth = () => {
    this.btn.classList.add('disabled');

    switch(this.native) {
      case 'iOS':
        if (window.webkit?.messageHandlers?.oAuth) {
          window.webkit?.messageHandlers?.oAuth?.postMessage(this.type);
          return;
        }
      case 'Android':
        if (window.android?.oAuth) {
          window.android?.oAuth(this.type);
          return;
        }
    }
    
    redirectTo("/users/auth/google_oauth2");

    // if (this.isNative) {
    //   redirectTo("/users/auth/google_oauth2");
    // } else {
    //   gapi.load('auth2', () => {
    //     const auth2 = gapi.auth2.init({
    //       client_id: '448585896004-97ukj371o4fhip8tffk46phat88q3qvr.apps.googleusercontent.com',
    //       cookiepolicy: 'single_host_origin',
    //       scope: 'email'
    //     });

    //     auth2.signIn().then(() => {
    //       redirectTo(`/users/oauth/google?token=${auth2.currentUser.get().getAuthResponse().id_token}&fallback_location=${status.redirectUrl || '/'}`, true);
    //     }).catch((error) => {
    //       this.handleError(error.error);
    //     });
    //   });
    // }
  }

  static handleError(error) {
    switch(error) {
      case 'popup_closed_by_user':
        toastr.error("구글 로그인이 취소되었습니다.");
        break;

      default:
        toastr.error(error.msg || "로그인에 실패했습니다.");
        break;
    }

    document.querySelector('.modal-footer .action-buttons .btn').classList.remove('disabled');
  }
}