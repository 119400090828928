import { status } from '../../lib';
import Slide from '../base';
import toastr from "toastr";

export default class KakaoSlide extends Slide {
  static slideName = 'sns';
  static type = 'kakao';
  
  static init = () => {
  }
  static refresh = () => {
    window.oAuthErrorHandler = this.handleError;
    this.auth();
  }
  static click = () => {
    this.auth();
  }

  static auth = () => {
    switch(this.native) {
      case 'iOS':
        if (window.webkit?.messageHandlers?.oAuth) {
          window.webkit?.messageHandlers?.oAuth?.postMessage(this.type);
          return;
        }
      case 'Android':
        if (window.android?.oAuth) {
          window.android?.oAuth(this.type);
          return;
        }
    }

    // redirectTo('/users/auth/kakao');
    // return;
  
    if (!Kakao.isInitialized())
      Kakao.init(window._kakaoKey);

    if (Kakao.isInitialized()) {
      Kakao.Auth.login({
        success: (auth) => {
          const url = `/users/oauth/kakao/callback?token=${auth["access_token"]}&refresh_token=${auth["refresh_token"]}&fallback_location=${status.redirectUrl || '/'}`;
          redirectTo(url, true);
        },
        fail: (error) => {
          this.handleError(error);
        }
      });
    } else {
      this.handleError({ error: 'init' });
    }
  }

  static handleError(error) {
    console.log(error.error_description);

    switch(error.error) {
      case 'access_denied':
        toastr.error("카카오 로그인에 실패하였습니다.");
        break;
        
      case 'init':
        toastr.error("카카오 로그인 초기화 실패!");
        break;

      default:
        toastr.error(error.msg || "로그인에 실패했습니다.");
        break;
    }

    document.querySelector('.modal-footer .action-buttons .btn').classList.remove('disabled');
  }
}