import API from '@/lib/api';
import { status, slideTo, slideBack } from '../lib';
import { validatePassword } from '@/lib/utils/validate';
import configs from '../../lib/configs';
import Slide from './base';
import ConfirmationSlide from './confirmation';

export default class SignUpSlide extends Slide {
  static slideName = 'sign-up';

  static init = () => {
    this.titles.forEach((e, i) => {
      e.addEventListener('click', evt => {
        this.showContent(i);
        evt.stopPropagation();
      });
    });

    this.slide.addEventListener('click', e => {
      this.showContent();
    });
  }

  static update = () => {
    if (validatePassword(this.input.value)) this.btn.classList.remove('disabled');
    else this.btn.classList.add('disabled');

    this.showContent();
  }

  static refresh = () => {
    this.placeholder.value = status.user.email || "";
  }

  static click = () => {
    if (configs.isTest) {
      slideTo(ConfirmationSlide);
      return;
    }

    if (status.isRequesting) return;
    status.isRequesting = true;
    
    status.user.password = this.input.value;
    API.sessions.signUp
      .call(status.user)
      .then(res => {
        status.user = res.data || {};

        switch(res.code) {
          case 1:
            slideTo(ConfirmationSlide);
            break;
        }
      }).catch(error => {
        switch(error.code) {
          case 2:
          case 3:
            Swal.fire({
              title: '오류',
              text: error.message || "알 수 없는 에러!",
              confirmButtonText: '확인',
            }).then((result) => {
              slideBack();
            });
            break;
          default:
            this.onError(error.message);
        }
      }).finally(() => {
        status.isRequesting = false;
      });
  }

  // 약관 관련 UI
  static get titles() {
    return this.slide.querySelectorAll('.agree-underline');
  }
  static get contents() {
    return this.slide.querySelectorAll('.wrap__agree-content');
  }

  static showContent(index = undefined) {
    this.titles.forEach((e, i) => {
      if (i === index) e.classList.add('clicked');
      else e.classList.remove('clicked');
    });
    this.contents.forEach((e, i) => {
      if (i === index) e.classList.add('active');
      else e.classList.remove('active');
    });
  }
}