import Turbolinks from 'turbolinks';
import API from '@/lib/api';
import { toggleModal, status, slideTo } from '../lib';
import { validatePassword } from '@/lib/utils/validate';
import Swal from 'sweetalert2';
import Slide from './base';
import ConfirmationSlide from './confirmation';
import NicknameSlide from './nickname';

export default class SignInSlide extends Slide {
  static slideName = 'sign-in';
  
  static init = () => {
    // 비밀번호 재설정 클릭시
    this.slide.querySelector('.btn-renew-password').addEventListener('click', e => {
      // 
      Swal.fire({
        titleText: "비밀번호 재설정",
        html: `비밀번호를 재설정할 수 있도록 본인 이메일(<span class="ptw-blue">${status.user.email}</span>)로 인증번호를 보내드립니다.`,
        showCancelButton: true,
        cancelButtonText: "취소",
        cancelButtonClass: "btn btn-blue",
        confirmButtonClass: "btn btn-gray",
        confirmButtonText: "진행"
      }).then(result => {
        if (result.isConfirmed) {
          if (status.isRequesting) return;
          status.isRequesting = true;
          status.user.reset_password = true;

          API.sessions.resend
            .call(status.user)
            .then(res => {
              slideTo(ConfirmationSlide);
            })
            .catch(error => {
              this.onError(error.message);
            })
            .finally(() => {
              status.isRequesting = false;
            });
        }
      });
    });
  }

  static update = () => {
    if (validatePassword(this.input.value)) this.btn.classList.remove('disabled');
    else this.btn.classList.add('disabled');
  }

  static refresh = () => {
    if (status.user?.reset_password !== undefined) delete status.user.reset_password;
    this.placeholder.value = status.user.email || "";
  }

  static click = () => {    
    if (status.isRequesting || !validatePassword(this.input.value)) return;
    status.isRequesting = true;

    status.user.password = this.input.value;
    API.sessions.signIn
      .call(status.user)
      .then(res => {
        status.user = res.data || {};

        switch(res.code) {
          case 1:
            slideTo(ConfirmationSlide);
            break;
          case 2:
            slideTo(NicknameSlide);
            break;
          case 3:
            // Swal.fire({
            //   title: '로그인 성공',
            //   text: "성공적으로 로그인되었습니다. (원래 안뜰 창임. 테스트용)",
            //   confirmButtonText: '확인',
            // }).then((result) => {
              toggleModal(false);
              redirectTo(status.redirectUrl, true);
            // });
            break;
        }
      }).catch(error => {
        this.onError(error.message);
      }).finally(() => {
        status.isRequesting = false;
      });
  }
}