import API from '@/lib/api';
import { status, toggleModal } from '../lib';
import { validateNickname } from '@/lib/utils/validate';
import configs from '../../lib/configs';
import Slide from './base';

export default class NicknameSlide extends Slide {
  static slideName = 'nickname';

  static get nicknameLength() { return document.querySelector('span.nickname-length > span.length'); }

  static update = () => {
    status.user.nickname = this.input.value;

    if (validateNickname(this.input.value)) this.btn.classList.remove('disabled');
    else this.btn.classList.add('disabled');

    // 닉네임 길이
    if (this.nicknameLength) {
      if (this.input.value.length !== 0 && (this.input.value.length > 8 || this.input.value.length < 2)) 
        this.nicknameLength.classList.add('error');
      else 
        this.nicknameLength.classList.remove('error');
      
      this.nicknameLength.innerHTML = this.input.value.length;
    }
  }

  static refresh = () => {
    this.input.value = status.user.nickname || "";
  }

  static click = () => {
    if (configs.isTest) {
      Swal.fire({
        title: '회원가입 완료',
        text: "회원가입이 성공적으로 완료되었습니다.",
        confirmButtonText: '확인',
      }).then((result) => {
        toggleModal(false);
        // redirect
      }).finally(() => {
        status.isRequesting = false;
      });
      return;
    }

    if (status.isRequesting) return;
    status.isRequesting = true;
    
    API.sessions.nickname
      .call(status.user)
      .then(res => {
        status.user = res.data || {};

        switch(res.code) {
          case 1:
            Swal.fire({
              title: '회원가입 완료',
              text: "회원가입이 성공적으로 완료되었습니다.",
              confirmButtonText: '확인',
            }).then((result) => {
              toggleModal(false);
              redirectTo(status.redirectUrl, true);
            });
            break;
        }
      }).catch(error => {
        switch(error.code) {
          case 1:
          case 2:
            this.onError(error.message);
            break;
          default:
            Swal.fire({
              title: '오류',
              text: error.message || "알 수 없는 에러!",
              confirmButtonText: '확인',
            }).then((result) => {
              // 뒤로 가기 기능
            });
            break;
        }
      }).finally(() => {
        status.isRequesting = false;
      });
  }
}