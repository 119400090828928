// https://dev.to/vijitail/pull-to-refresh-animation-with-vanilla-javascript-17oc

export default class SwipeUI {
  pStart = { x: 0, y: 0, top: 0 };
  pCurrent = { x: 0, y: 0, top: 0 };
  // pEnd = { x: 0, y: 0, top: 0 };
  _swiping = false;

  constructor(options = {}) {
    if (!options.target) throw("target must be set.");
    this.target = options.target
    this.onStart = options.onStart;
    this.onSwipe = options.onSwipe;
    this.onEnd = options.onEnd;

    options.target.addEventListener('touchstart', e => { this.swipeStart(e); }, false);
    options.target.addEventListener('touchmove', e => { this.swipe(e); }, false);
    options.target.addEventListener('touchend', e => { this.swipeEnd(e); }, false);
    if (options.mouse) {
      options.target.addEventListener('mousedown', e => {
        this._swiping = true;
        this.swipeStart(e);
      }, false);
      options.target.addEventListener('mousemove', e => {
        if (this._swiping) {
          this.swipe(e);
          e.preventDefault();
        }
      }, false);
      options.target.addEventListener('mouseup', e => {
        if (this._swiping) {
          this.swipeEnd(e);
          this._swiping = false;
        }
      }, false);
    }
  }

  get pEnd() { return this.pCurrent; }
  get dY() { return this.pEnd.y - this.pStart.y; }
  get dX() { return this.pEnd.x - this.pStart.x; }
  get dT() { return this.pEnd.top - this.pStart.top }

  swipeStart(e) {
    if (e.targetTouches) {
      const touch = e.targetTouches[0];
      this.pStart.x = touch.screenX;
      this.pStart.y = touch.screenY;
    } else {
      this.pStart.x = e.screenX;
      this.pStart.y = e.screenY;
    }
    this.pStart.top = window.pageYOffset || document.documentElement.scrollTop;
    // this.pStart.top = this.target.scrollTop;
    // this.pStart.top = window.scrollY;
    if (this.onStart) this.onStart(this);
  }

  swipe(e) {
    if (e.changedTouches) {
      const touch = e.changedTouches[0];
      this.pCurrent.x = touch.screenX;
      this.pCurrent.y = touch.screenY;
    } else {
      this.pCurrent.x = e.screenX;
      this.pCurrent.y = e.screenY;
    }
    this.pCurrent.top = window.pageYOffset || document.documentElement.scrollTop;
    // this.pCurrent.top = this.target.scrollTop;
    // this.pCurrent.top = window.scrollY;
    // if (this.target.scrollTop <= 0 && this.dY + this.dT < 0 && e.cancelable) e.preventDefault();
    if (this.onSwipe) this.onSwipe(this);
  }

  swipeEnd(e){
    if (e.changedTouches) {
      const touch = e.changedTouches[0];
      this.pCurrent.x = touch.screenX;
      this.pCurrent.y = touch.screenY;
    } else {
      this.pCurrent.x = e.screenX;
      this.pCurrent.y = e.screenY;
    }
    this.pCurrent.top = window.pageYOffset || document.documentElement.scrollTop;
    // this.pCurrent.top = this.target.scrollTop;
    if (this.onEnd) this.onEnd(this);
  }

  get isPullDown() {
    return this.dY < 0 && (
        (Math.abs(this.dX) <= 100 && Math.abs(this.dY) >= 300)
        || (Math.abs(this.dX)/Math.abs(this.dY) <= 0.3 && this.dY >= 60)
    );
  }

  print() {
    console.log(this.pStart);
    console.log(this.pEnd);
    console.log(this.dX);
    console.log(this.dY);
  }
}