import ClipboardJS from 'clipboard';
import toastr from 'toastr';
import Kakao from '@/lib/kakao';
import Swal from 'sweetalert2';

export function initLinkShare(element) {
  switch(appData().native) {
    case "iOS":
      if (window.webkit?.messageHandlers?.pasteBoard) {
        element.addEventListener('click', e => {
          window.webkit.messageHandlers.pasteBoard.postMessage(element.dataset.clipboardText)
          toastr.success('링크 주소가 복사되었습니다.');
          Swal.close();
        });
      } else {
        const textArea = element.querySelector('textarea');
        textArea.classList.remove('hide');
        textArea.value = element.dataset.clipboardText;
        element.addEventListener('click', e => {
          iOSLinkShare(textArea);
        });
      }
      break;
    default:
      const c = new ClipboardJS(element);
      c.on('success', e => {
        // alert('링크 주소가 복사되었습니다.');
        toastr.success('링크 주소가 복사되었습니다.');
        Swal.close();
      });

      c.on('error', e => {
        alert('복사에 실패하였습니다.');
      });
  }
}

function iOSLinkShare(el) {
  let oldContentEditable = el.contentEditable,
      oldReadOnly = el.readOnly,
      range = document.createRange();

  el.contentEditable = true;
  el.readOnly = false;
  range.selectNodeContents(el);

  let s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  // A big number, to cover anything that could be inside the element.
  el.setSelectionRange(0, 9999);

  el.contentEditable = oldContentEditable;
  el.readOnly = oldReadOnly;

  document.execCommand('copy');
  toastr.success('링크 주소가 복사되었습니다.');
  Swal.close();
}

export function facebookShare(data = {}) {
  Swal.close();
  if (!window.fb) return false;

  // console.log(data.href);
  window.fb.ui({
    method: 'share',
    href: data.href
  }, function(res) {
    // console.log(res);
  });
}

export function kakaoShare(data = {}) {
  // console.log(data);
  const feed = {
    objectType: 'feed',
    content: {
      title: data.title,
      description: data.desc,
      imageUrl: data.imageUrl,
      link: {
        webUrl: data.href,
        mobileWebUrl: data.href,
      },
    },
    social: {
      likeCount: parseInt(data.likeCount) || 0,
      commentCount: parseInt(data.reviewCount) || 0,
      // sharedCount: 30,
    },
    buttons: [
      {
        title: data.btnTitle || '자세히 보기',
        link: {
          webUrl: data.href,
          mobileWebUrl: data.href,
        },
      },
    ]
  }

  switch(appData().native) {
    case "iOS":
      window.webkit.messageHandlers.kakaoShare.postMessage(JSON.stringify(feed))
      break;
    default:
      Kakao.Link.sendDefault(feed);
  }

  Swal.close();
}