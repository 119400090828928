// https://stackoverflow.com/a/25621277
// AutoHeight
export function autoHeight(text) {
  $(text).on('input focus', function() {
    this.style.height = 'auto';
    const maxHeight = this.dataset.maxHeight;
    if (maxHeight && this.scrollHeight >= maxHeight) {
      this.style.height = `${maxHeight}px`;
    } else {
      this.style.height = `${this.scrollHeight}px`;
    }
  });
}