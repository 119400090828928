import { SWIPER_CLASS, updateNav } from '../lib';

export default class Slide {
  // 속성
  static slideName = undefined;
  static navType = 'back';
  // 함수
  static init = () => {};
  static refresh = () => {};
  static update = () => {};
  static click = () => {};

  static initSlide() {
    if (this.slide) this.slide.controller = this;

    // SNS
    if (this.slideName === 'sns' && this.type)
      document.querySelector('.swiper-slide > .sessions-sns').setAttribute("data-type", this.type);
      
    this.init();

    this.input?.addEventListener('focus', e => { this.updateUI(); });
    this.input?.addEventListener('input', e => { this.updateUI(); });
    this.input?.addEventListener('blur', e => { this.updateUI(); });

    this.input?.addEventListener('keyup', e => {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.btn?.click();
      }
    });

    this.btn?.addEventListener('click', e => { 
      this.updateUI();
      this.click();
    });

    this.icon?.addEventListener('click', e => { this.togglePassword(); });
  }

  static refreshSlide() {
    updateNav(this.navType);
    this.refresh();
    this.updateUI();
    // console.log('refresh')

    setTimeout(() => {
      // this.input?.focus();
      if (this.input) this.input.autofocus = true;
      // this.input?.click();
      this.input?.focus();
    }, 800);
  }

  static get id() { return `swiper-slide-${this.slideName}`; }
  static get slide() { return document.querySelector(`.${SWIPER_CLASS} #${this.id}`); }
  static get newSlide() { return $(`#${this.id}`).clone(); }

  // HTMLElements
  static get placeholder() {
    return this.slide.querySelector(`.placeholder-text`);
    // return this.slide.querySelector(`.placeholder`);
  }
  static get btn() { return this.slide.querySelector(`.btn`); }
  static get input() { return this.slide.querySelector(`.custom-input:not(.placeholder-text)`); }
  static get label() { return this.slide.querySelector(`.wrapper-input:not(.read-only) .label-password`); }
  static get wrapper() { return this.slide.querySelector(`.wrapper-input:not(.read-only) .wrapper-custom-input`); }
  static get detail() { return this.slide.querySelector(`p.detail`); }
  static get icon() { return this.slide.querySelector('.password-show'); }

  // Methods
  static get isNative() { return !!appData().native }
  static get native() { return appData().native }
  static updateUI() {
    this.update();

    if (this.input?.value.length !== 0 || document.activeElement === this.input) this.label?.classList?.add('write');
    else this.label?.classList?.remove('write');

    this.onError();
  }

  static onError(value = undefined) {
    if (!this.wrapper || !this.detail) return;

    if (value) {
      this.wrapper.classList.add('error');
      this.detail.innerHTML = value;
    } else {
      this.wrapper.classList.remove('error');
      this.detail.innerHTML = "";
    }
  }

  static togglePassword() {
    if (!this.icon) return;

    if (this.icon.classList.contains('show')) {
      this.icon.classList.remove('show');
      this.input.type = "password";
    } else {
      this.icon.classList.add('show');
      this.input.type = "text";
    }
  }
}