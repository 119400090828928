import API from '../lib/api';
import SuperTheme from '../model/super_theme';

const ITEMS_COUNT = 20

export class ThemeTable {
  page = 1
  // SuperTheme[]
  superThemes = []
  fetching = false
  fetchable = true
  index
  // all, online, offline, creative, search
  type

  get table() {
    // if (this.index === undefined) return document.querySelector('.themes-cell-wrapper')
    // else return document.querySelector(`.themes-cell-wrapper[data-index='${this.index}'`)
    return document.querySelector(`.themes-cell-wrapper[data-type='${this.type}'`)
  }

  constructor(options = {}) {
    if (options.index != null) this.index = options.index
    if (options.type != null) this.type = options.type
  }

  search(q = '') {
    if (!q || this.fetching) return
    this.fetching = true

    API.search.search
      .call({ query: q })
      .then(res => {
        this.superThemes = res.data.map(rawData => {
          return new SuperTheme(rawData);
        });
        this.updateView()
      })
      .finally(() => {
        this.fetching = false
      })
  }

  fetchNext(reset = false) {
    if (this.fetching) return
    if (reset) {
      this.page = 1
      this.fetchable = true
    }
    if (!this.fetchable) return
    this.fetching = true

    API.themes.indexList
      .call({
        type: this.type,
        page: this.page,
        items: ITEMS_COUNT,
      })
      .then((res) => {
        // this.superThemes = res.data.map((d) => new SuperTheme(d))
        if (res.data.length < ITEMS_COUNT) this.fetchable = false
        this.update(res.data.map((d) => new SuperTheme(d)))
        this.page += 1
      })
      .finally(() => {
        this.fetching = false
      })
  }

  update(superThemes = []) {
    if (!this.table) return

    if (this.page === 1) {
      this.table.innerHTML = this.renderedItems(superThemes)
    } else {
      this.table.innerHTML += this.renderedItems(superThemes)
    }
  }

  renderedItems(superThemes = []) {
    return render({
      template: 'theme-cell',
      data: superThemes,
    })
  }

  updateView() {
    if (!this.table) return

    if (this.superThemes.length == 0) {
      if (this.type === undefined || this.type === 'search') {
        this.table.innerHTML = '<div class="message">검색결과가 없습니다.</div>'
      } else {
        this.table.innerHTML = '<div class="message">결과가 없습니다.</div>'
      }
    } else {
      this.table.innerHTML = render({
        template: 'theme-cell',
        data: this.superThemes,
      })
    }
  }
}