import API from '@/lib/api';
import { status, slideTo, SLIDES, slideBack } from '../lib';
import { validateConfirmation } from '@/lib/utils/validate';
import configs from '../../lib/configs';
import Slide from './base';
import ChangeEmailSlide from './change_email';
import NicknameSlide from './nickname';
import ResetPasswordSlide from './reset_password';

export default class ConfirmationSlide extends Slide {
  static slideName = 'confirmation';
  static navType = 'hide';

  static init = () => {
    // 이메일 변경
    // this.slide.querySelector(`.btn-change`).addEventListener('click', e => {
    //   if (status.isRequesting) return;
    //
    //   slideTo(ChangeEmailSlide);
    // });

    // 재발송
    this.slide.querySelector(`.btn-resend`).addEventListener('click', e => {
      if (status.isRequesting) return;
      
      API.sessions.resend
        .call(status.user)
        .then(res => {
          // status.user = res.data || {};
  
          switch(res.code) {
            case 1:
              Swal.fire({
                title: '이메일 재발송',
                text: "인증번호가 담긴 이메일을 재발송했습니다.",
                confirmButtonText: '확인',
              }).then(result => {
                this.onError();
              });
              break;
          }
        }).catch(error => {
          switch(error.code) {
            default:
              Swal.fire({
                title: '오류',
                text: error.message || "알 수 없는 에러!",
                confirmButtonText: '확인',
              }).then((result) => {
                slideBack();
              });
              break;
          }
        }).finally(() => {
          status.isRequesting = false;
        });
    });
  }

  static update = () => {
    status.user.confirmation = this.input.value;
    
    if (validateConfirmation(this.input.value)) this.btn.classList.remove('disabled');
    else this.btn.classList.add('disabled');
  }

  static refresh = () => {
    this.placeholder.value = status.user.email || "";
  }

  static click = () => {
    if (configs.isTest) {
      slideTo(NicknameSlide, true);
      return;
    }

    if (status.isRequesting) return;
    status.isRequesting = true;
    
    API.sessions.confirmation
      .call(status.user)
      .then(res => {
        status.user = res.data || {};

        switch(res.code) {
          case 1:
            slideTo(NicknameSlide, true);
            break;
          case 2:
            slideTo(ResetPasswordSlide, true);
            break;
        }
      }).catch(error => {
        switch(error.code) {
          case 1:
          case 3:
          case 4:
            this.onError(error.message);
            break;
          default:
            Swal.fire({
              title: '오류',
              text: error.message || "알 수 없는 에러!",
              confirmButtonText: '확인',
            }).then((result) => {
              slideBack();
            });
            break;
        }
      }).finally(() => {
        status.isRequesting = false;
      });
  }
}