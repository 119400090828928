const MODE = {
  DEVELOPMENT: 'development',
  TEST: 'test',
  STAGE: 'stage',
  PRODUCTION: 'production'
}

export default new class {
  mode = MODE.STAGE

  get isTest() {
    return [MODE.TEST, MODE.DEVELOPMENT].includes(this.mode);
  }
}