import API from '@/lib/api';
import { status, slideTo } from '../../lib';
import Slide from '../base';
import toastr from "toastr";
import { redirectTo } from '../../../lib/core';

export default class FacebookSlide extends Slide {
  static slideName = 'sns';
  static type = 'facebook';
  
  static init = () => {
  }
  static refresh = () => {
    window.oAuthErrorHandler = this.handleError;
    this.auth();
  }
  static click = () => {
    this.auth();
  }
  
  static auth = () => {
    this.btn.classList.add('disabled');

    // redirectTo("/users/auth/facebook", true);
    // window.open("/users/auth/facebook");
    switch(this.native) {
      case 'iOS':
        if (window.webkit?.messageHandlers?.oAuth) {
          window.webkit?.messageHandlers?.oAuth?.postMessage(this.type);
          return;
        }
      case 'Android':
        if (window.android?.oAuth) {
          window.android?.oAuth(this.type);
          return;
        }
    }

    if (this.isNative) {
      redirectTo("/users/auth/facebook");
    } else {
      if (!window.fb) {
        this.handleError(undefined);

        return;
      }
      
      window.fb.login((response) => {
        if(response.status === 'connected') {          
          redirectTo(`/users/oauth/facebook?token=${response.authResponse.accessToken}&fallback_location=${status.redirectUrl || '/'}`, true);
        } else {
          this.handleError(response);
        }
      }, {scope: 'email', return_scopes: true, enable_profile_selector: true });
    }
  }
  
  static handleError(error) {
    switch(error) {
      case 'popup_closed_by_user':
        toastr.error("로그인이 취소되었습니다.");
        break;

      default:
        toastr.error(error.msg || "로그인에 실패했습니다.");
        break;
    }

    document.querySelector('.modal-footer .action-buttons .btn').classList.remove('disabled');
  }
}