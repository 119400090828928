import API from "@/lib/api";

export default class PointManager {
    _cash = 0;
    targetUrl;
    isRequesting = false;
    _onlyCharge = false;
    _popup;
    _popupTick;

    get cash() {
        return this._cash?.toLocaleString() || "0";
    }

    set cash(value) {
        if (typeof value === 'string') {
            this._cash = parseInt(value);
        } else if (typeof value === 'number') {
            this._cash = value;
        } else this._cash = 0;
    }

    async refresh() {
        await API.point.check
            .call()
            .then(res => {
                this._cash = res.data.cash;
                this.targetUrl = res.data.target_url;
            });
    }

    // 구매/충전/만료/플레이
    // options: { themeId: Int, callback: Function }
    async default(options) {
        if (!options.themeId) return;
        if (this.isRequesting) return;
        if (options.refresh !== false) await this.refresh();
        // if (options.price > this.cash) {
        //     this.charge(options);
        // } else {
        //     Swal.fire({
        //         title: "현재 포인트: " + this.cash + "P",
        //         text: this.numberWithCommas(options.price) + "P를 이용하여 테마 이용권을 구매합니다.",
        //         showCancelButton: true,
        //         cancelButtonText: "취소",
        //         confirmButtonText: "구매하기"
        //     }).then(result => {
        //         if (!result.isConfirmed) return;
        //         this._check(options);
        //     })
        // }

        await API.point.checkStatus
            .call({
                theme_id: options.themeId
            })
            .then(res => {
                switch (res.data.result) {
                    case "charge":
                        Swal.fire({
                            title: "현재 포인트: " + this.cash + " 포인트",
                            text: "포인트 충전이 필요합니다.",
                            showCancelButton: true,
                            confirmButtonText: "충전하기",
                            cancelButtonText: "취소"
                        }).then(result => {
                            if (!result.isConfirmed) return;
                            if (!!appData().native) {
                                Swal.fire("플레이더월드 웹사이트로 이동해 진행하세요.")
                            } else {
                                this._charge(options);
                            }
                        });
                        break;

                    case "purchase":
                        Swal.fire({
                            title: "현재 포인트: " + this.cash + "P",
                            text: this.numberWithCommas(options.price) + "P를 이용하여 테마 이용권을 구매합니다.",
                            showCancelButton: true,
                            cancelButtonText: "취소",
                            confirmButtonText: "구매하기"
                        }).then(result => {
                            if (!result.isConfirmed) return;
                            this._purchase(options);
                        })
                        break;
                    case "expired":
                        Swal.fire({
                            title: "구매한 이력이 있습니다.",
                            text: "다시 구매하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonText: "구매하기",
                            cancelButtonText: "취소"
                        }).then(result => {
                            if (this.cash < options.price) {
                                Swal.fire({
                                    title: "현재 포인트: " + this.cash + " 포인트",
                                    text: "포인트 충전이 필요합니다.",
                                    showCancelButton: true,
                                    confirmButtonText: "충전하기",
                                    cancelButtonText: "취소"
                                }).then(result => {
                                    if (!result.isConfirmed) return;
                                    if (!!appData().native) {
                                        Swal.fire("플레이더월드 웹사이트로 이동해 진행하세요.")
                                    } else {
                                        this._charge(options);
                                    }
                                });
                            } else {
                                if (!result.isConfirmed) return;
                                this._purchase(options);
                            }
                        })
                        break;
                    default:
                        location.reload();
                        break;
                }
            })
            .catch(err => {
                this.alert(" 구매정보 확인 실패", "잠시 후 다시 시도해주세요.");
            })
            .finally(() => {
                this.isRequesting = false;
            })


    }

    // 구매이력 Request
    _check(options) {
        if (this.isRequesting) return;
        this.isRequesting = true;

        API.point.checkStatus
            .call({
                theme_id: options.themeId
            })
            .then(res => {
                if (res.status === "success") {
                    return res.data.result;
                    // switch (res.data.result) {
                    //     case "purchase":
                    //         console.log("purchase")
                    //         this._purchase(options);
                    //         break;
                    //     case "expired":
                    //         console.log("expired");
                    //         Swal.fire({
                    //             title: "구매한 이력이 있습니다.",
                    //             text: "다시 구매하시겠습니까?",
                    //             showCancelButton: true,
                    //             confirmButtonText: "구매하기",
                    //             cancelButtonText: "취소"
                    //         }).then(result => {
                    //             if (!result.isConfirmed) return;
                    //             this._purchase(options);
                    //         })
                    //         break;
                    //     default:
                    //         console.log(res.data.result);
                    //         location.reload();
                    //         break;
                    // }
                } else {
                    this.alert("동기화 실패", "잠시 후 다시 시도해주세요.");
                }
            })
            .catch(err => {
                this.alert("동기화 실패", "잠시 후 다시 시도해주세요.");
            })
            .finally(() => {
                this.isRequesting = false;
            })
    }

    // 구매 Request
    _purchase(options) {
        if (this.isRequesting) return;
        this.isRequesting = true;

        API.point.purchase
            .call({
                theme_id: options.themeId
            })
            .then(res => {
                switch (res.status) {
                    case "success":
                        this.cash = res.data?.cash;
                        this.alert(`잔여 포인트: ${this.cash} 포인트`, "테마를 구매했습니다.", options.callback);
                        break;

                    default:
                        this.alert("테마 구매에 실패했습니다.", res.data?.message);
                        break;
                }
            })
            .catch(err => {
                this.alert("테마 구매에 실패했습니다.", "잠시 후 다시 시도해주세요.");
            })
            .finally(() => {
                this.isRequesting = false;
            });
    }

    // 충전
    // options: { themeId: Int, title: String, price: Int, callback: Function }
    async chargeOnly(options) {
        // 잘못된 옵션
        if (this.isRequesting) return;
        if (options.refresh !== false) await this.refresh();

        Swal.fire({
            title: "현재 포인트: " + this.cash + " 포인트",
            text: "포인트를 충전하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "충전하기",
            cancelButtonText: "취소"
        }).then(result => {
            if (!result.isConfirmed) return;
            if (!!appData().native) {
                Swal.fire("플레이더월드 웹사이트로 이동해 진행하세요.")
            } else {
                this._onlyCharge = true;
                this._charge(options);
            }
        });
    }

    // 충전 Request
    // options: { themeId: Int, title: String, price: Int, callback: Function }
    _charge(options) {
        API.point.token
            .call()
            .then(res => {
                this._popup = window.open(this.targetUrl + "/charge?access_token=" + res.data.token,
                    '_blank',
                    'height=760px, width=960px, left= 0px, top= 0px, location=no, menubar=no, resizable=no, status=no, toolbar=no',
                    false
                )
                try {
                    this._popup.focus();
                } catch (e) {
                    this.alert("팝업이 차단되어 있습니다.", "설정 > 브라우저 > 팝업 차단을 해제하세요.");
                    return;
                }
                this._lastCash = this.cash;
                this._popupTick = setInterval(() => {
                    if (this._popup.closed) {
                        clearInterval(this._popupTick);
                        this.refresh()
                            .then(result => {
                                if (this._lastCash === this._cash) return;
                                if (this._onlyCharge) {
                                    // this.alert("포인트 충전 완료")
                                    location.reload();
                                } else {
                                    this._purchase(options);
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                            .finally(() => {

                            })

                    }
                }, 3000);

            })
            .catch(err => {
                this.alert("포인트 충전에 실패했습니다.", "잠시 후 다시 시도해주세요.");
            });
    }

    alert(title, text, callback = undefined) {
        Swal.fire({
            title: title,
            text: text,
            confirmButtonText: '확인'
        }).then(result => {
            if (callback) callback(result);
        });
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}
