import Swiper from 'swiper';

Swiper.prototype.swipeTo = function(index, retry = 10) {
  if (retry === 0) return;
  if (this.slides.length < index + 1) {
    // DOM이 다 그려지고 나서 이동하게끔.
    setTimeout(() => {
      this.swipeTo(index, retry - 1);
    }, 100);
  } else {
    this.slideTo(index, 0, false);
    this.update();
  }
}