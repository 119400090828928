const { default: API } = require("@/lib/api");
import toastr from 'toastr'
import { toggleModal } from '../sessions/lib';
import SelectSlide from '../sessions/slides/select';

export function achieveCoupon(keycode = '', redirectUrl = '') {
  return API.point.coupon
    .call({ keycode })
    .then(result =>{
        if(result.status === "success") {
            Swal.fire({
                title: "쿠폰을 사용했습니다.",
                text: result.data.message,
                confirmButtonText: '확인'
            });
        } else {
            Swal.fire({
                title: "쿠폰을 사용할 수 없습니다.",
                text: result.data.message,
                confirmButtonText: '확인'
            });
        }
    })
    .catch(err => {
        // console.log(err)
        toastr.error(err.message)
        switch (err.status) {
            case 401:
                toggleModal(true, SelectSlide, {
                    redirectUrl: redirectUrl || location.href,
                });
                break
        }
    })
}
