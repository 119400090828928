const DEFAULT_LAT = '37.556990';
const DEFAULT_LNG = '126.927545';
export const MAP_CONTENT_CLASS = 'map-content';

export function initMap(container) {
  if (!container || !window.daum) return;

  // 좌표 초기화
  let lat = container.dataset.lat;
  let lng = container.dataset.lng;
  if (lat === "") lat = undefined;
  if (lng === "") lng = undefined;

  let address = container.dataset.address;
  if (address === "") address = undefined;

  // 주소-좌표 변환 객체를 생성합니다
  const geocoder = new window.daum.maps.services.Geocoder();
  let center = new window.daum.maps.LatLng(DEFAULT_LAT, DEFAULT_LNG);
  if (lat && lng) {
    center = new window.daum.maps.LatLng(lat, lng);
  } else if (address) {
    // 주소로 좌표를 검색합니다
    geocoder.addressSearch(address, function(result, status) {
      // 정상적으로 검색이 완료됐으면
      if (status === window.daum.maps.services.Status.OK)
        // 이동할 경도, 위도 설정
        center= new window.daum.maps.LatLng(result[0].y, result[0].x);
    });
  }

  // 결과값으로 받은 위치를 마커로 표시합니다
  const marker = new window.daum.maps.Marker({ position: center });

  const mapOptions = {
    // 지도 중심 좌표 설정
    center: center,
    level: 2,
    marker: marker
  };

  let mapObject = new window.daum.maps.Map(container, mapOptions);
  mapObject.center = center;
  
  mapObject.addControl(new window.daum.maps.MapTypeControl(), window.daum.maps.ControlPosition.TOPRIGHT);
  marker.setMap(mapObject);

  // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
  mapObject.setCenter(center);

  // DOM에 KakaoMap 오브젝트 추가
  container.mapObject = mapObject;
}

// options = { map, focus, lat, lng, address }
export function initMaps() {
  for(const container of document.getElementsByClassName(MAP_CONTENT_CLASS)) {
    initMap(container);
  }
}

// onload(() => {
//   initMaps();
// });