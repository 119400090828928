import Status from '@/lib/utils/status';
import Swiper from 'swiper';
// import EmailSlide from './slides/email';
import SelectSlide from './slides/select';

export const SWIPER_CLASS = 'sessions-swiper';
export const MODAL_CLASS = 'sessions-modal';

// 세션뷰 상태관리
export const status = new Status();
status.user = {};
status.redirectUrl = '/';
let removing = false;

// Swiper
const swiper = () => { return document.querySelector(`.${SWIPER_CLASS}`)?.swiper; };
// Slide 애니매이션 속도(시간/ms)
const SPEED = 500;

export function initSwiper() {
  const e = document.querySelector(`.${SWIPER_CLASS}`);
  if (!e) return;

  if (e.swiper) {
    status.user = {};
    removing = false;
    e.swiper.removeAllSlides();
  } else {
    new Swiper(e, {
      slidesPerView: 1,
      spaceBetween: 16,
      allowTouchMove: false,
      on: {
        slideChange: (s) => {
          currentSlide(s)?.controller?.refreshSlide();
        }
      }
    });
  }
}

// 현재 Slide 객체
function currentSlide(swiper) {
  if (swiper) return swiper.slides[swiper.activeIndex];
}

// 특정 슬라이드 생성, 이동
export function slideTo(slide, replace = false, speed = SPEED) {
  if (removing && replace) return;
  if (!swiper() || !slide) return;

  // 에러 너무 많아서 그냥 일단 애니매이션 없앰
  // speed = 0;

  // const index = swiper.activeIndex;
  // document.querySelector(`.${SWIPER_CLASS} > .swiper-wrapper`).innerHTML += `<div class="swiper-slide" id="swiper-slide-${slide.slideName}">${slide.newSlide.html()}</div>`;
  // if (slide === SelectSlide) {
  //   swiper().appendSlide(slide.newSlide);
  //   swiper().appendSlide(EmailSlide.newSlide);
  //   swiper().removeSlide(1);
  // } else 
  swiper().appendSlide(slide.newSlide);
  // if (swiper().slides.length === 2) {
  //   console.log('heee')
  //   swiper().update();
  //   swiper().slideTo(1, 1);
  //   swiper().slideTo(0, 1);
  // }
  // swiper().addSlide(1, slide.newSlide);
  // swiper().update();
  // swiper().slideReset();
  // console.log("slideTO")
  slide.initSlide();
  if (swiper().slides.length === 1) {
    // console.log(1);
    // swiper().appendSlide(`<div class="swiper-slide"></div>`);
    // swiper().update();
    // swiper().removeSlide(1);
    // swiper().update();
    slide.refreshSlide();
  } else swiper().slideNext(speed);

  // 교체 애니매이션
  if (replace) {
    removing = true;
    const index = swiper().slides.length - 2;
    // 직전의 슬라이드를 삭제함
    setTimeout(() => {
      swiper().removeSlide(index);
      removing = false;
    }, speed);
  }

  setTimeout(() => {
    swiper().update();
  }, speed);
}

// 뒤로가기
export function slideBack(speed = SPEED, count = 1) {
  // alert("slideback")
  if (count === 0) return;
  if (removing) return;
  if (!swiper()) return;
  removing = true;

  const index = swiper().activeIndex;
  
  swiper().slidePrev(speed);
  setTimeout(() => {
    swiper().removeSlide(index);
    removing = false;
    slideBack(speed, count - 1);
  }, speed);
}

// MODAL, NAVBAR 보이기 토글
export function toggleModal(visible = undefined, slide = SelectSlide, data = {}) {
  // alert("tt")
  const modal = document.querySelector(`.${MODAL_CLASS}`);
  if (!modal) return;

  if (visible !== undefined) {
    if (visible) modal.classList.remove('hide');
    else modal.classList.add('hide');
  } else {
    if (modal.classList.contains('hide')) modal.classList.remove('hide');
    else modal.classList.add('hide');
  }

  // console.log(data);
  if (data.user) status.user = data.user;
  // if (data.redirectUrl) 
  status.redirectUrl = data.redirectUrl || location.href || '/';
  if (data.hideNav !== undefined) status.hideNav = data.hideNav;

  if (!modal.classList.contains('hide')) {
    initSwiper();
    slideTo(slide);
    // slide.initSlide();
  }
}

export function updateNav(type = 'back') {
  const navbar = document.querySelector(`.${MODAL_CLASS} .modal-navbar`);
  if (!navbar) return;

  if (status.hideNav && swiper().slides.length === 1) {
    // navbar.classList.add('hide');
    // return;
    type = 'hide';
  }
  navbar.classList.remove('hide');

  if (swiper().slides.length === 1) {
    // navbar.classList.add('first');
    // return;
    type = 'close';
  }

  switch(type) {
    case 'close':
      navbar.classList.add('first');
      break;
    case 'hide':
      navbar.classList.add('hide');
      break;
    default:
      navbar.classList.remove('first');
  }
}

// const slide = SLIDES.SELECT;

// console.log('select load');
// onload(() => {
//   slide.init = () => {
//     console.log("init!");
//     slide.slide.querySelector('.platform-btn.email').addEventListener('click', e => {
//       slideTo(SLIDES.EMAIL);
//     });
//   }
// });