import API from '@/lib/api'
import toastr from 'toastr'
import { achieveCoupon } from '@/point/coupon'

export function usePrize() {
  let requesting = false

  function requestUsePrize(rouletteId, roulettePrizeId, el) {
    if (requesting) {
      toastr.info('요청을 처리하는 중입니다.')
      return
    }
    if (!rouletteId || !roulettePrizeId) return
    requesting = true

    return API.roulette.use
      .withId(rouletteId)
      .call({ roulette_prize_id: roulettePrizeId })
      .then((res) => {
        toastr.success('사용되었습니다.')

        if (el) {
          el.innerText = "사용완료"
          el.classList.add('disabled')
          el.dataset.used = "true"
        }

        return res
      })
      .catch((res) => {
        // toastr.error('오류가 발생하였습니다.')
        toastr.error(res.data.message)
      })
      .finally(() => {
        requesting = false
      })
  }

  function linkEvents() {
    document.querySelectorAll('a.use-prize-btn:not(.disabled)').forEach((el) => {
      const rouletteId = el.dataset.rouletteId
      const roulettePrizeId = el.dataset.roulettePrizeId
      if (!rouletteId || !roulettePrizeId) return

      const itemTitle = el.dataset.itemTitle || ''

      el.addEventListener('click', (e) => {
        e.preventDefault()
        if (el.dataset.used == 'true') return false

        Swal.fire({
          title: `${itemTitle} 상품 사용`,
          text: "사용하시겠습니까?",
          showCancelButton: true,
          // showDenyButton: true,
          confirmButtonText: '사용',
          cancelButtonText: '취소',
          // denyButtonText: '취소'
        }).then((result) => {
          if (result.isConfirmed) {
            requestUsePrize(rouletteId, roulettePrizeId, el)
          }
        });

        return false
      })
    })

    document.querySelectorAll('a[data-type="ptw_coupon"]').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault()
        console.log(el.dataset.redirectUrl)
        achieveCoupon(el.dataset.keycode, el.dataset.redirectUrl)
      })
    })
  }

  return {
    requesting,
    requestUsePrize,
    linkEvents,
  }
}
