import Model from './model';

export default class SuperTheme extends Model {
  static _keys = {
    id: 'fake_id',
    title: 'title',
    convertedTitle: 'converted_title',
    summary: 'summary',
    url: 'url',
    category: 'category',
    genre: 'genre',
    location: 'location',
    thumbnail: 'thumbnail',
    poster: 'poster',
    new: 'new',
    minPrice: 'min_price',
    maxPrice: 'max_price',
  }

  static _defaultValues = {
    new: false,
    minPrice: 0,
    maxPrice: 0,
  }

  get needCharge() {
    return this.minPrice > 0
  }

  get priceS() {
    if (this.minPrice === 0 && this.maxPrice === 0) return '무료'
    if (this.minPrice === this.maxPrice) return `${this.maxPrice.toLocaleString()}원`
    else return `${this.minPrice.toLocaleString()}원 ~ ${this.maxPrice.toLocaleString()}원`
  }

  configure() {
    if (this.summary) {
      this.summary = this.summary.replace(/<br\s*\/?>/, ' ')
    }
    // this.needCharge = this.minPrice > 0
    // console.log(this.needCharge)
  }
}