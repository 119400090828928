import { version } from "toastr";
import { appData } from "../core";

export default function appVersion() {
  if (!appData().version) return undefined;

  return new Version(appData().version);
}

class Version {
  major = 1
  minor = 0
  build = 0

  constructor(str) {
    const arr = str.split('.').map(s => {
      return parseInt(s);
    });

    this.major = arr[0] || 1;
    this.minor = arr[1] || 0;
    this.build = arr[2] || 0;
  }

  atLeast(o) {
    let v;
    if (o.constructor == String) v = new Version(o);
    else v = o;

    return this.major > v.major ||
      (this.major == v.major && this.minor > v.minor) ||
      (this.major == v.major && this.minor == v.minor && this.build >= v.build)
  }
}