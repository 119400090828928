import objectMap from '../utils/objectMap';
import EndPoint from './endPoint';
import { GET, POST, PATCH, DELETE, PUT, ID } from './const';

// 밑의 API 선언부를 간단하게 하기 위한 간단한 필러 함수
const convert = object => {
	// 예외 처리
	if (typeof(object) != "object") throw(`${object} is not an object`);

	// 배열인 경우 EndPoint 생성
	if (Array.isArray(object)) {
		return new EndPoint({
			url: object[1],
			method: object[0]
		});
	};

	// Object인 경우 재귀호출
	return objectMap(object, v => { return convert(v) });
};

// CRUD 관련 Endpoint 선언 helper 메소드
const crud = model => {
	// API(Backend)에서는 스네이크 케이스를 씀.
	const modelName = model.toSnakeCase();

	return {
		index: [GET, `${modelName}`],
		create: [POST, `${modelName}`],
		read: [GET, `${modelName}/${ID}`],
		update: [PATCH, `${modelName}/${ID}`],
		delete: [DELETE, `${modelName}/${ID}`]
	};
};

// Usage
// Ex) signin with email/pw
// API.sessions.signIn.call({
// 	email: 'mechuri@playthe.world',
// 	password: 'showmethemoney'
// });
// API 선언부
const concerns = {
	commentable(prefix) {
		return {
			index: [GET, `${prefix}/comments`],
			create: [POST, `${prefix}/comments`],
			update: [PATCH, `${prefix}/comments/${ID}`],
			delete: [DELETE, `${prefix}/comments/${ID}`],
			like: [POST, `${prefix}/comments/${ID}/like`],
			report: [POST, `${prefix}/comments/${ID}/report`],
		}
	}
}
const API = convert({
  main: {
		checkTestCode: [POST, `test_code`]
  },
	// 사용자 세션관련 API
	sessions: {
		email: [GET, `sessions/email`],
		signIn: [POST, `sessions/sign_in`],
		signUp: [POST, `sessions/sign_up`],
		signOut: [DELETE, `sessions/sign_out`],
		nickname: [POST, `sessions/nickname`],
		confirmation: [POST, `sessions/confirmation`],
		resend: [POST, `sessions/resend`],
		changeEmail: [PATCH, `sessions/change_email`],
		resetPassword: [PATCH, `sessions/reset_password`],
		updateNickname: [PATCH, `sessions/update_nickname`],
		agree: [POST, `sessions/agree`],
		destroy: [DELETE, `sessions/destroy`],
		current: [GET, `sessions/current`],
		oauth: {
			kakao: [GET, `users/oauth/kakao/callback`],
			naver: [GET, `users/oauth/naver`],
			google: [GET, `users/oauth/google`],
			facebook: [GET, `users/oauth/facebook`],
			apple: [GET, `users/oauth/apple`],
		},
		phoneNumber: [GET, `sessions/phone_number`],
		confirmationPhone: [POST, `sessions/confirmation_phone`],
		guest: [GET, `sessions/guest`],
	},
	more: {
		updateProfileImage: [POST, `more/profile_image`],
		updateNickname: [PATCH, `more/nickname`],
	},
	storage: {
		finishedPlays: [GET, 'storage/finished_plays'],
		likedThemes: [GET, 'storage/liked_themes']
	},
	themes: {
		banners: [GET, `themes/banners`],
		like: [GET, `themes/${ID}/like`],
		recommend: [GET, `themes/${ID}/recommend`],
		topics: [GET, `themes/topics`],
		custom: [POST, `themes/${ID}/custom`],
		indexList: [GET, `themes/index_list`],
	},
	point: {
		check: [GET, `point/check`],
		token: [POST, `point/token`],
		purchase: [POST, `point/purchase`],
		charge: [POST, `point/charge`],
		purchaseList: [GET, `point/list/theme`],
		chargeList: [GET, `point/list/point`],
		couponList: [GET, `point/list/coupon`],
		coupon: [POST, `point/coupon`],
		checkStatus: [GET, `point/check_status`]
	},
	reviews: {
		create: [POST, `reviews`],
		update: [PATCH, `reviews/${ID}`],
		delete: [DELETE, `reviews/${ID}`],
		like: [POST, `reviews/${ID}/like`],
		theme: [GET, `reviews/theme/${ID}`],
		report: [POST, `reviews/${ID}/report`],
		comments: concerns.commentable(`reviews/${ID}`)
	},
	plays: {
		submitAnswer: [POST, `plays/submit_answer`],
		current: [GET, `plays/current`],
		theme: [POST, `plays/theme/${ID}`],
		stages: {
			index: [GET, `plays/stages`],
			show: [GET, `plays/stages/${ID}`],
			on: [POST, `plays/stages/on`],
			entities: [GET, `plays/stages/${ID}/entities`]
		},
		hints: {
			index: [GET, `plays/hints`],
			use: [POST, `plays/hints/${ID}/use`],
		},
		items: {
			index: [GET, `plays/items`],
			use: [POST, `plays/items/${ID}/use`],
		},
		retry: [POST, `plays/retry`],
		failed: [POST, `plays/failed`],
	},
	reports: {
		play: [POST, `reports/play/${ID}`]
	},
	// superThemes: {
	// 	...crud('superThemes')
	// },
	admin: {
		app: {
			mainPost: [PATCH, `admin/app/main_post`],
			topics: [PATCH, `admin/app/topics`],
			banners: {
				list: [GET, `admin/app/banners`],
				create: [POST, `admin/app/banners`],
				update: [PATCH, `admin/app/banners`],
				status: [PUT, `admin/app/banners`],
				destroy: [DELETE, `admin/app/banners`]
			}
		},
		themes: {
			updateStatus: [PATCH, `admin/themes/${ID}/status`],
			seed: [POST, `admin/themes/seed`],
		}
	},
	search: {
		search: [POST, `search/search`],
	},
	roulette: {
		roll: [POST, `roulette/${ID}/roll`],
		use: [POST, `roulette/${ID}/use`],
	}
});

export default API;