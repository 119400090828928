import Slide from '../base';
import toastr from "toastr";

export default class NaverSlide extends Slide {
  static slideName = "sns";
  static type = 'naver';

  static init = () => {
  }
  static refresh = () => {
    window.oAuthErrorHandler = this.handleError;
    this.auth();
  }
  static click = () => {
    this.auth();
  }

  static auth = () => {
    this.btn.classList.add('disabled');

    switch(this.native) {
      case 'iOS':
        if (window.webkit?.messageHandlers?.oAuth) {
          window.webkit?.messageHandlers?.oAuth?.postMessage(this.type);
          return;
        }
      case 'Android':
        if (window.android?.oAuth) {
          window.android?.oAuth(this.type);
          return;
        }
    }
    
    redirectTo('/users/auth/naver', true);
    // window.open('/users/auth/naver');
  }

  static handleError(error) {
    console.log(error);

    switch(error) {
      case 'popup_closed_by_user':
        toastr.error("네이버 로그인이 취소되었습니다.");
        break;
        
      default:
        toastr.error(error.msg || "로그인에 실패했습니다.");
        break;
    }

    document.querySelector('.modal-footer .action-buttons .btn').classList.remove('disabled');
  }
}

