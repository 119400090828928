import { status, slideBack } from '../../lib';
import Slide from '../base';
import toastr from 'toastr';
import { redirectTo } from '../../../lib/core';
import appVersion from '../../../lib/utils/appVersion';

export default class AppleSlide extends Slide {
  static slideName = 'sns';
  static type = 'apple';
  
  static init = () => {
  }
  static refresh = () => {
    window.oAuthErrorHandler = this.handleError;
    window.appleAuth = this.directAuth;
    this.auth();
  }
  static click = () => {
    this.auth();
  }
  
  static auth = () => {
    this.btn.classList.add('disabled');

    switch(this.native) {
      case 'iOS':
        if (window.webkit?.messageHandlers?.oAuth) {
          window.webkit?.messageHandlers?.oAuth?.postMessage(this.type);
          return;
        }
      case 'Android':
        if (!appVersion().atLeast("1.2.3")) {
          alert("앱 업데이트가 필요합니다.");
          slideBack();
          return;
        }
        if (window.android?.oAuth) {
          window.android?.oAuth(this.type);
          return;
        }
    }
    
    this.directAuth();
  }

  static directAuth() {
    redirectTo('/users/auth/apple');
    // window.open('/users/auth/apple');
    // return;

    // const appleID = window.appleID;
    // if (!appleID) {
    //   slideBack();
    //   return;
    // }

    // appleID.auth.signIn()
    //   .then(res => {
    //     console.log(res);
    //     const url = `/users/oauth/apple?id_token=${res?.authorization?.id_token}&code=${res?.authorization?.code}&fallback_location=${status.redirectUrl || '/'}`;
    //     redirectTo(url, true);
    //   })
    //   .catch(err => {
    //     this.handleError(err);
    //   });
  }
  
  static handleError(error) {
    console.log(error);

    switch(error.error) {
      case 'popup_closed_by_user':
        toastr.error("애플 로그인이 취소되었습니다.");
        break;

      case 'ios13':
        toastr.error("iOS 13 미만에서는 지원하지 않습니다.");
        slideBack();
        break;
        
      default:
        toastr.error(error.msg || "로그인에 실패했습니다.");
        break;
    }

    document.querySelector('.modal-footer .action-buttons .btn').classList.remove('disabled');
  }
}