const BASE_KEYS = {
  id: "id",
  modelType: "model_type",
  // frontStatus: undefined
};

export default class Model {
  // Getters
  get keys() {
    return {
      ...BASE_KEYS,
      ...this.constructor._keys
    }
  }
  get defaultValues() {
    return {
      ...this.constructor._defaultValues
    }
  }

  // 각 모델에 맞는 Key값 선언
  static _keys = undefined;
  // 각 모델에 맞는 관계 선언
  static _relations = [];
  // 각 모델의 기본값 선언
  static _defaultValues = {};

  // Constructor
  constructor(rawData = {}) {
    // Model의 선언이 제대로 되었는 지 확인
    if (this.constructor._keys === undefined) throw(`${this.className}: keys must be set.`);
    if (this.constructor._keys.constructor !== Object) throw(`${this.className}: keys must be an Object`);
    if (!Array.isArray(this.constructor._relations)) throw(`${this.className}: relations must be an Array`);
    // RawData가 Object인지
    // if (this.rawData.constructor != Object)
    //   throw(`${this.className}: rawData must be an Object`)

    // rawData으로 부터 Key에 맞춰서 현재 모델 생성
    for (const [k, v] of Object.entries(this.keys)) {
      this[k] = rawData[v];
    };

    this.configure();
    // 기본값 지정
    this.setDefaultValues();
  }

  // ---------------------------- //
  // Instance Methods

  configure() {}

  // 기본값 지정
  setDefaultValues() {
    for(const [k, v] of Object.entries(this.defaultValues)) {
      if (this[k] === undefined || this[k] === null) this[k] = v;
    }
  }

  // Json(Object)으로 변환하는 함수
  toJson() {
    let result = {};
    for (let [k, v] of Object.entries(this.keys)) {
      if (v === undefined) continue;
      result[v] = this[k];
    }
    return result;
  }

  // 조건으로 이 record를 테스트 => Boolean
  testBy(predicate = {}) {
    for (let [k, v] of Object.entries(predicate))
      if (this[k] !== v) return false; // 조건의 하나라도 틀리면 false
    
    return true;
  }

  // ---------------------------- //
  // Helper Methods
  // 클래스명
  get className() { return this.constructor.name; }
  // Object에서 key로 쓰일 값
  get nameKey() { return this.constructor.nameKey; }
  static get nameKey() { return this.name.toCamelCase(); }
  // API통신 중 Object에서 key로 쓰일 값
  get apiNameKey() { return this.constructor.apiNameKey; }
  static get apiNameKey() { return this.name.toSnakeCase(); }
}