import API from '@/lib/api';
import { status, slideTo } from '../lib';
import { validateEmail } from '@/lib/utils/validate';
import configs from '../../lib/configs';
import Slide from './base';
import SignUpSlide from './sign_up';
import SignInSlide from './sign_in';

export default class EmailSlide extends Slide {
  static slideName = 'email';

  static update = () => {
    status.user.email = this.input.value;

    if (validateEmail(this.input.value)) this.btn.classList.remove('disabled');
    else this.btn.classList.add('disabled');
  }

  static refresh = () => {
    this.input.value = status.user.email || "";
  }

  static click = () => {
    if (configs.isTest) {
      slideTo(SignUpSlide);
      return
    }

    if (status.isRequesting) return;
    status.isRequesting = true;

    API.sessions.email
      .call(status.user)
      .then(res => {
        status.user = res.data || {};

        switch(res.code) {
          case 1:
            slideTo(SignUpSlide);
            break;
          case 2:
            slideTo(SignInSlide);
            break;
        }
      }).catch(error => {
        this.onError(error.message);
      }).finally(() => {
        status.isRequesting = false;
      });
  }
}